import React, { useContext } from 'react'
import { Link } from 'gatsby';
import { FaCartShopping } from "react-icons/fa6";
import { StoreContext } from '../../store';

const Cart = () => {
    const { cart } = useContext(StoreContext);
    return (
        <Link to="/cart">
            {cart && cart?.lineItems?.length > 0 &&
               <div className='bg-[orange] inline-block ml-10 py-[4px] px-[10px] rounded-full absolute mt-[-18px] ml-[26px] '> <p className='m-0 text-white text-[12px] font-medium'>{cart?.lineItems?.length}</p></div>
            }
            <FaCartShopping className="cart-icon  ml-[15px]" size={26} />
        </Link>)
}

export default Cart;