import React, { useEffect, useState } from "react";
import { useLocation } from 'react-use';
import MenuDrawer from "./drawer";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { IoCallSharp } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoArrowUpSharp } from "react-icons/io5";
import { windowScroll } from "../utils/windowScroll";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import { GetRequest } from "../utils/request";
import Provider from "../store";
import Cart from "../components/cart/index"
import { FaUser } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";

const Layout = ({ children }) => {
    const { pathname } = useLocation();
    const [details, setDetails] = useState([])

    let shopifyToken;
    if (typeof window !== 'undefined') {
        shopifyToken = localStorage.getItem('sh-kal-ac');
    }

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [scrollPosition, setScrollPosition] = useState(false);

        // current year
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentYear(new Date().getFullYear());
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);
    // current year

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 400) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        GetRequest(`${process.env.REACT_APP_API_URL}/details/getdetails`)
            .then((response) => {
                setDetails(response?.data?.data);
            }).catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <Provider>
            <header className="relative z-50 bg-[#2984bd]">
                <div className="container">
                    <div className="flex justify-between items-center lg:h-auto h-[90px]">
                        <Link to="/" className="flex items-center">
                            <StaticImage
                                src="../assets/images/logo.png"
                                alt="logo"
                                className="w-[130px]"
                            />
                        </Link>

                        <nav className='lg:block hidden'>
                            <ul className=" flex items-center lg:flex-nowrap flex-wrap lg:justify-center justify-start justify-left">
                                {navLinks.map((link, i) => (
                                    <li key={i} className="relative group lg:w-auto w-full">
                                        <Link to={link.handle} className={`${pathname === link.handle && "!text-[#2984bd] bg-[#fff]"} text-[#fff] text-[18px] lg:inline-block block w-full	lg:py-[33px] py-[10px] xl:px-[25px] lg:px-[17px] px-[15px] hover:bg-[#fff] hover:text-[#2984bd]`}>{link.title}</Link>
                                        {link.subLinks && (
                                            <ul className="duration-500 left-0 shadow-[0px_0px_15px_0px_rgba(0,0,0,0.2)] group-hover:visible group-hover:h-auto group-hover:overflow-visible grid bg-white text-black md:absolute relative top-[100%] md:w-[280px] w-[100%] z-[1111] invisible overflow-hidden h-0">
                                                {link.subLinks.map((item, i) => (
                                                    <li key={i} className="w-fill">
                                                        <Link to={item.handle} className={`${pathname === item.handle && "!text-[#fff] bg-[#2984bd]"} border-b w-full inline-block border-[#3c62e921] text-[16px] hover:bg-[#2984bd] hover:text-white px-[16px] py-[12px] `}>{item.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                                {!shopifyToken ?
                                    <Link className="text-[#fff] text-[18px] font-medium ml-[15px]" to="/login"><FaUser size={22}/></Link>
                                    :
                                    <div className="flex items-center gap-4">
                                        <Link className="text-[#fff] text-[18px] font-medium ml-[15px]" to="/account"><FaUserCircle size={22}/></Link>
                                    </div>
                                }
                               <Cart/>
                            </ul>
                        </nav>
                        <div className="inline-block lg:hidden">
                            <MenuDrawer navLinks={navLinks} />
                        </div>
                    </div>
                </div>
            </header>
            {children}
            <footer className="bg-footer-img bg-[#2984bd] bg-center bg-contain bg-no-repeat">
                <div className="container">
                    <div className="flex md:flex-nowrap flex-wrap md:py-0 py-[25px]">
                        <div className="lg:w-[22%] md:w-[28%] w-[100%] md:py-[30px] py-[12px] px-[10px] border-[#ffffff45] md:border-r border-r-none">
                            <Link className="text-white hover:text-[#ef7f1a] h-[100%] flex items-center md:justify-center justify-start group" to='tel:+919056111675'><IoCallSharp className='mr-[10px] text-[30px] group-hover:text-white group-hover:scale-[1.2] duration-700 min-w-[30px]' />{details[0]?.number}</Link>
                        </div>
                        <div className="lg:w-[50%] md:w-[40%]  w-[100%] md:py-[30px] py-[12px] px-[10px] border-[#ffffff45] md:border-r border-r-none">
                            <p className="mb-0 text-white hover:text-[#ef7f1a] h-[100%] flex items-center md:justify-center justify-start group "><FaLocationDot className='mr-[10px] text-[30px] group-hover:text-white group-hover:scale-[1.2] duration-700 min-w-[30px]' />{details[0]?.address}</p>
                        </div>
                        <div className="lg:w-[28%] md:w-[33%] w-[100%] md:py-[30px] py-[12px] px-[10px] ">
                            <Link className="text-white hover:text-[#ef7f1a] h-[100%] flex items-center md:justify-center justify-start group" to='mailto:sales@mycloudcam.in'>
                                <IoMdMail className='mr-[10px] text-[30px] group-hover:text-white group-hover:scale-[1.2] duration-700 min-w-[30px]' />sales@mycloudcam.in</Link>
                        </div>
                    </div>
                </div>
                <hr className="border-[#ffffff45]" />
                <div className="container">
                    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 md:py-[50px] py-[30px]">
                        <div className="my-[10px] lg:my-[0]">
                            <Link to="/" onClick={windowScroll}>
                                <StaticImage
                                    src="../assets/images/logo.png"
                                    alt="logo"
                                    className="w-[130px]"
                                />
                            </Link>
                            <div className="mt-[24px] mb-[15px]">
                                <p className="text-[#fff]">MyCloudCam is a cloud-based surveillance solution designed to provide businesses and individuals with advanced security and monitoring capabilities.</p>
                            </div>
                            <div>
                                <h5 className="text-xl text-white mb-[15px]">Download Our App</h5>
                                <div className="grid grid-cols-2 gap-[10px] max-w-[310px]">
                                <Link href="https://play.google.com/store/apps/details?id=com.onnet.vsaas&hl=en" target="_blank"
                                        class="flex items-center justify-center w-full px-[15px] py-[7px] text-center text-white bg-white hover:bg-[#e2eff3] rounded-2xl"
                                        rel="noreferrer">
                                        <svg class="w-7" viewBox="-9 0 274 274" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path
                                                    d="M188.81319,178.874645 C221.272218,161.051727 245.880297,147.470853 248.001319,146.415618 C254.78648,142.806714 261.79324,133.256838 248.001319,125.838536 C243.548228,123.506467 219.573289,110.347687 188.81319,93.3795092 L146.171146,136.443648 L188.81319,178.874645 Z"
                                                    fill="#FFD900"></path>
                                                <path
                                                    d="M146.171146,136.443648 L10.3940643,273.286517 C13.5808739,273.708611 17.1792251,272.864423 21.4212696,270.532353 C30.3274526,265.657168 124.739324,214.098388 188.81319,178.885198 L146.171146,136.443648 Z"
                                                    fill="#F43249"></path>
                                                <path
                                                    d="M146.171146,136.443648 L188.81319,93.5905562 C188.81319,93.5905562 30.9711459,7.45172685 21.4212696,2.36549437 C17.8229184,0.233919759 13.7919209,-0.399221214 10.1830173,0.233919759 L146.171146,136.443648 Z"
                                                    fill="#00EE76"></path>
                                                <path
                                                    d="M146.171146,136.443648 L10.1830173,0.233919759 C4.6641385,1.51075405 0,6.38593954 0,16.3579099 C0,32.270853 0,244.003747 0,257.162527 C0,266.290309 3.60890354,272.864423 10.3940643,273.497564 L146.171146,136.443648 Z"
                                                    fill="#00D3FF"></path>
                                            </g>
                                        </svg>
                                            <div class="flex flex-col ml-2 leading-4 text-left md:ml-3">
                                                <span class="text-sm text-black">Get it on</span>
                                                <span class="text-base font-semibold text-black">
                                                Play Store
                                            </span>
                                        </div>
                                    </Link>
                                    <Link href="https://play.google.com/store/apps/details?id=com.onnet.vsaas&hl=en" target="_blank"
                                        class="flex items-center justify-center w-full px-[15px] py-[7px] text-center text-white bg-white hover:bg-[#e2eff3] rounded-2xl"
                                        rel="noreferrer">
                                        <svg class="w-7" viewBox="0 0 40 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path
                                                    d="M32.6226 23.7016C32.6026 20.0267 34.2591 17.253 37.6118 15.2103C35.7359 12.5167 32.902 11.0347 29.1601 10.7443C25.6177 10.464 21.7461 12.8171 20.3292 12.8171C18.8324 12.8171 15.3998 10.8445 12.7057 10.8445C7.13769 10.9346 1.22048 15.3004 1.22048 24.1822C1.22048 26.8057 1.69945 29.516 2.65738 32.3131C3.93461 35.988 8.54465 45 13.3542 44.8498C15.8688 44.7897 17.645 43.0574 20.9179 43.0574C24.091 43.0574 25.7375 44.8498 28.5414 44.8498C33.3909 44.7797 37.5619 36.5888 38.7793 32.9039C32.2733 29.8298 32.6226 23.8919 32.6226 23.7016ZM26.9748 7.25968C29.6989 4.01535 29.4494 1.06142 29.3696 0C26.9648 0.140187 24.1808 1.64219 22.5943 3.49466C20.848 5.4773 19.8203 7.93058 20.0398 10.6943C22.6442 10.8945 25.019 9.55274 26.9748 7.25968Z"
                                                    fill="black"></path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="40" height="45" fill="white"></rect>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <div class="flex flex-col ml-2 leading-4 text-left md:ml-3">
                                            <span class="text-sm text-black">Get it on</span>
                                            <span class="text-base font-semibold text-black">
                                            App Store
                                        </span>
                                        </div>
                                    </Link>
                                    <Link href="https://play.google.com/store/apps/details?id=com.onnet.vsaas&hl=en" target="_blank"
                                        class="flex items-center justify-center w-full px-[15px] py-[7px] text-center text-white bg-white hover:bg-[#e2eff3] rounded-2xl"
                                        rel="noreferrer">
                                    <svg class="w-7" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" fill="none">
                                        <g>
                                            <rect x="4" y="12" width="56" height="36" rx="6" fill="#3B82F6"></rect>
                                            <rect x="12" y="20" width="40" height="20" fill="white"></rect>
                                            <rect x="12" y="48" width="40" height="6" fill="#3B82F6"></rect>
                                        </g>
                                    </svg>
                                    <div class="flex flex-col ml-2 leading-4 text-left md:ml-3">
                                        <span class="text-sm text-black">Get it on</span>
                                        <span class="text-base font-semibold text-black">Smart TV</span>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {footerLinks.map((link, i) => (
                            <div className="my-[10px] lg:my-[0]" key={i}>
                                <h3 className="text-white font-medium	pb-[8px] mb-[25px] text-[20px] xl:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[60px] before:bg-[#fff] " >{link.title}</h3>
                                <ul className="grid gap-[10px] sm:gap-[12px] md:gap-[15px]">
                                    {link.subLinks.map((item, i) => (
                                        <li key={i}>
                                            <Link to={item.handle} className={`${pathname === item.handle && "!text-[#ef7f1a]"} text-[16px] text-[#fff] hover:text-[#ef7f1a] `} onClick={windowScroll}>{item.link}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="bg-[#fff]">
                    <div className="container">
                        <div className="flex md:justify-between justify-center items-center md:flex-nowrap flex-wrap py-[25px]">
                            <p className="text-[16px] mb-0 md:text-left text-center md:w-1/3 w-full">© Copyright {currentYear} MyCloudCam. {details[0]?.copyRight}</p>
                            <p className="text-[16px] mb-0 lg:text-left lg:w-auto md:w-1/3 w-full text-center">{details[0]?.powered}</p>
                            <div className="flex gap-[10px] md:w-1/3 md:justify-end max-md:justify-center w-full md:mt-0 mt-[20px]">
                                <Link to='https://www.facebook.com/mycloudcam/' target="_blank" className='text-[20px] text-[#fff] w-[40px] h-[40px] rounded-full bg-[#2984bd] hover:bg-[#ef7f1a] flex justify-center items-center'> <FaFacebookF /></Link>
                                <Link to='https://twitter.com/My_cloudcam' target="_blank" className='text-[20px] text-[#fff] w-[40px] h-[40px] rounded-full bg-[#2984bd] hover:bg-[#ef7f1a] flex justify-center items-center'> <FaTwitter /></Link>
                                <Link to='https://www.linkedin.com/company/my-cloud-cam/?viewAsMember=true' target="_blank" className='text-[20px] text-[#fff] w-[40px] h-[40px] rounded-full bg-[#2984bd] hover:bg-[#ef7f1a] flex justify-center items-center'> <FaLinkedinIn /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <button className={`' group flex justify-center items-center 2xl:right-[50px] md:right-[30px] duration-500 right-[15px] bg-[#fff] hover:bg-[#ef7f1a] w-[50px] h-[50px] shadow-md shadow-[#a4cae3] rounded-[10px] z-50 ' ${scrollPosition ? 'opacity-100 overflow-visible fixed transition-[0.8s] bottom-[30px]' : 'bottom-[102%]'}`}
                onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}>
                <IoArrowUpSharp className="text-[#2984bd] text-[22px] group-hover:text-[#fff]" />
            </button>
        </Provider>
    );
};

const navLinks = [
    {
        title: "Plans",
        handle: "",
        subLinks: [
            {
                title: "Residential",
                handle: "/residential-plans/"
            },
            {
                title: "Enterprise",
                handle: "/enterprise-plans/"
            },
            {
                title: "Best Fit Plan",
                handle: "/best-fit-plan"
            }
        ]
    },
    {
        title: "Gateway",
        handle: "",
        subLinks: [
            {
                title: "Residential Gateway",
                handle: "/residential-gateway"
            },
            {
                title: "Enterprise Gateway",
                handle: "/enterprise-gateway"
            }
        ]
    },
    {
        title: "Resources",
        handle: "",
        subLinks: [
            {
                title: "Cloud Gateway Resources",
                handle: "/cloud-gateway-resources"
            },
            {
                title: "Case Studies",
                handle: "/case-studies"
            },
            {
                title: "FAQs",
                handle: "/faqs"
            },
            {
                title: "Media",
                handle: "/media"
            },
            {
                title: "Blog",
                handle: "/blogs"
            }
        ]
    },
    {
        title: "Partners",
        handle: "",
        subLinks: [
            {
                title: "System Integrators",
                handle: "/system-integrators"
            },
            {
                title: "Distributors",
                handle: "/distributors"
            },
            {
                title: "ISP/Telecoms",
                handle: "/isp-telecoms"
            },
            {
                title: "OEM",
                handle: "/oem"
            },
            {
                title: "White Labelled",
                handle: "/white-labelled"
            }
        ]
    },
    {
        title: "Contact Us",
        handle: "",
        subLinks: [
            {
                title: "Contact",
                handle: "/contact-us"
            },
            {
                title: "Submit Ticket",
                handle: "/submit-ticket"
            }
        ]
    }
]

const footerLinks = [
    {
        title: "Product",
        subLinks: [
            {
            link: "My Cloud Cam",
            handle: "/mycloudcam"
            },
            {
                link: "Residential Gateway Pricing",
                handle: "/residential-gateway-pricing"
            }
        ]
    },
    {
        title: "Quick Links",
        subLinks: [
            {
                link: "About Us",
                handle: "/about-us"
            },
            {
                link: "Resources",
                handle: "/resource"
            },
            {
                link: "Solutions",
                handle: "/solution"
            },
            {
                link: "Careers",
                handle: "/career"
            },
            {
                link: "FAQs",
                handle: "/faqs"
            }
        ]
    },
    {
        title: "Company Info",
        subLinks: [
            {
                link: "Contact Us",
                handle: "/contact-us"
            },
            {
                link: "Privacy Policy",
                handle: "/privacy-policy"
            },
            {
                link: "Terms & Conditions",
                handle: "/terms-and-condition"
            },
            {
                link: "Refund Policy",
                handle: "/refund-policy"
            },
            {
                link: "Sitemap",
                handle: "/sitemap"
            }
        ]
    },
];

export default Layout;