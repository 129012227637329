import React, { createContext, useEffect, useState } from 'react';
import Client from 'shopify-buy';
import { GetRequest } from '../utils/request';

//INITIALIZE CLIENT TO RETURN CONTENT IN STORE'S PRIMARY LANGUAGE
const client = Client.buildClient({
    domain: process.env.SHOPIFY_APP_URL,
    storefrontAccessToken: process.env.SHOPIFY_STOREFRONT_TOKEN
});

const defaultStoreContext = {
    cartQuantity: 0,
    cart: {},
    addLineItem: () => { },
    removeLineItem: () => { },
    updateLineItem: () => { },
    addDiscount: () => { },
    removeDiscount: () => { },
    userData: null,
    setUserData: () => { },
    trigger: 0,
    setTrigger: () => { },
    addAddress: () => { },
    resetCartHandler: () => { },
    activeCollection: "",
    setActiveCollection: () => { },
    searchKey: "",
    setSearchKey: () => { },
    filteredSearch: [],
    setFilteredSearch: () => { },
    openCheckout: false,
    setOpenCheckout: () => { },
    deviceQuantity: {},
    setDeviceQuantity: () => { },
    annualDiscount: "",
    setAnnualDiscount: () => { },
    semiAnnualDiscount: "",
    setSemiAnnualDiscount: () => { },
    calculateDiscount: () => { }
}

export const StoreContext = createContext(defaultStoreContext);

const Provider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [deviceQuantity, setDeviceQuantity] = useState({})
    const [cart, setCart] = useState(null);
    const [cartQuantity, setCartQuantity] = useState(0);
    const [trigger, setTrigger] = useState(0);
    const [activeCollection, setActiveCollection] = useState("");
    const [searchKey, setSearchKey] = useState("");
    const [filteredSearch, setFilteredSearch] = useState([]);
    const [openCheckout, setOpenCheckout] = useState(false);
    const [annualDiscount, setAnnualDiscount] = useState("")
    const [semiAnnualDiscount, setSemiAnnualDiscount] = useState("")


    //FETCHES CHECKOUT ON PAGE RENDER
    useEffect(() => {
        fetchCheckout();
    }, [trigger]);

    //CREATES CHECKOUT TOKEN URL
    const fetchCheckout = async () => {
        const isBrowser = typeof window !== 'undefined';
        const existingCheckoutId = isBrowser ? localStorage.getItem('k-checkout') : null;
        if (existingCheckoutId) {
            try {
                const checkout = await client.checkout.fetch(existingCheckoutId);
                if (checkout?.lineItems) {
                    setCartQuantity(checkout.lineItems.length);
                    setCart(checkout);
                } else {
                    const newCheckout = await client.checkout.create();
                    localStorage.setItem('k-checkout', newCheckout?.id);
                    setCart(newCheckout);
                    setCartQuantity(0);
                }
            } catch (error) {
                const newCheckout = await client.checkout.create();
                localStorage.setItem('k-checkout', newCheckout?.id);
                setCart(newCheckout);
                setCartQuantity(0);
            }
        } else {
            try {
                const newCheckout = await client.checkout.create();
                localStorage.setItem('k-checkout', newCheckout?.id);
                setCart(newCheckout);
                setCartQuantity(0);
            } catch (error) {
                console.error("Error creating new checkout:", error);
            }
        }
    };


    //ADDS LINEITEM TO CART
    const addLineItem = async (variantId, quantity) => {
        try {
            if (!variantId || !quantity) {
                throw new Error("Variant ID and quantity are required.");
            }

            const lineItemsToAdd = [{ variantId, quantity }];

            // Add line items to the cart
            const updatedCart = await client?.checkout?.addLineItems(cart?.id, lineItemsToAdd);

            // Fetch the updated checkout information
            const checkout = await client?.checkout?.fetch(cart?.id);

            // Update the cart state
            setCartQuantity(checkout?.lineItems?.length || 0);
            setCart(updatedCart);
        } catch (error) {
            console.error("Error adding line item to cart:", error.message || error);
        }
    };


    //UPDATE LINEITEM IN CART
    const updateLineItem = async (id, quantity) => {
        const lineItemsToUpdate = [{ id, quantity }];
        const updatedCart = await client.checkout.updateLineItems(cart?.id, lineItemsToUpdate);
        const checkout = await client.checkout.fetch(cart?.id);
        setCartQuantity(checkout?.lineItems?.length)
        setCart(updatedCart);
    };

    //REMOVE LINEITEM FROM CART
    const removeLineItem = async (variantId) => {
        const lineItemsToDelete = [variantId];
        const updatedCart = await client.checkout.removeLineItems(cart?.id, lineItemsToDelete);
        const checkout = await client.checkout.fetch(cart?.id);
        setCartQuantity(checkout?.lineItems?.length)
        setCart(updatedCart);
    };

    //ADD DISCOUNT TO CART ITEMS
    const addDiscount = async (discountCode) => {
        const updatedCart = await client.checkout.addDiscount(cart.id, discountCode);
        if (updatedCart.userErrors.length > 0) {
            return { applied: false, message: "Coupon Code is Invalid!" };
        } else {
            setCart(updatedCart);
            return { applied: true };
        }
    };

    //REMOVES DISCOUNT TO CART ITEMS
    const removeDiscount = async () => {
        const updatedCart = await client.checkout.removeDiscount(cart.id);
        setCart(updatedCart);
    };

    //ADD ADDRESS TO CHECKOUT
    const addAddress = async (shippingAddress) => {
        const updatedCart = await client.checkout.updateShippingAddress(cart.id, shippingAddress);
        setCart(updatedCart);
    };

    let shopifyToken;
    if (typeof window !== 'undefined') {
        shopifyToken = localStorage.getItem('sh-kal-ac');
    }

    // FETCHES DATA WITH TOKEN
    useEffect(() => {
        if (shopifyToken) {
            GetRequest(`${process.env.REACT_APP_API_URL}/user/token/` + shopifyToken).then(response => {
                setUserData(response?.data?.data?.customer);
            }).catch(err => {
                console.log(err);
            })
        } else {
            setUserData(null);
        }
    }, [shopifyToken]);

    //RESET CART ON SUCCESSFUL ORDER
    function resetCartHandler() {
        if (typeof window !== 'undefined') {
            localStorage.removeItem('k-checkout');
        }
        setCart(null);
        setCartQuantity(0)
    }

    const calculateDiscount = (cart) => {
        if (!cart?.lineItems) return 0;

        return cart.lineItems.reduce((totalDiscount, item) => {
            const price = Number(item.variant.priceV2.amount || 0);
            if (item.variant.title === "Semi Annually") {
                totalDiscount = (price * 0.05).toFixed(2);
                setSemiAnnualDiscount("Semi Annual")
            }
            if (item.variant.title === "Annually") {
                totalDiscount = (price * 0.1).toFixed(2);
                setAnnualDiscount("Annual")

            }
            return totalDiscount;
        }, 0);
    };

    return (
        <StoreContext.Provider
            value={{
                cart,
                cartQuantity,
                userData,
                setUserData,
                addLineItem,
                updateLineItem,
                removeLineItem,
                addDiscount,
                removeDiscount,
                trigger,
                setTrigger,
                addAddress,
                resetCartHandler,
                activeCollection,
                setActiveCollection,
                searchKey,
                setSearchKey,
                filteredSearch,
                setFilteredSearch,
                openCheckout,
                setOpenCheckout,
                deviceQuantity,
                setDeviceQuantity,
                calculateDiscount,
                annualDiscount,
                setAnnualDiscount,
                semiAnnualDiscount,
                setSemiAnnualDiscount
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export default Provider;